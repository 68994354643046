























































































import { Component, Vue } from 'vue-property-decorator'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import AuthService from '@bertazzoni/common/src/services/AuthService'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
@Component({
  components: {
    FormError,
    Icons
  }
})
export default class Reset extends Vue {
  private email = ''
  private emailChecked = false
  private loading = false
  private errorMessage = ''

  onSubmit() {
    this.loading = true
    this.$validator.validateAll().then((isValid) => {
      if (!isValid) {
        this.loading = false
        return
      }
      this.sendEmail()
    })
  }
  goLogin() {
    this.$router.push('/login')
  }

  async sendEmail() {
    this.loading = true
    try {
      await AuthService.resetPw(this.email)
      this.emailChecked = true
    } catch (error) {
      if (error.statusCode === 404) this.errorMessage = 'Email not found'
      else this.errorMessage = error.message
    }
    this.loading = false
  }

  uncheckEmail() {
    this.emailChecked = false
  }
}
